/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */
@import '~datatables.net-dt/css/jquery.dataTables.css';
@import '~datatables.net-buttons-dt/css/buttons.dataTables.css';
@import './assets/css/flags.css';
@import '../node_modules/angular-archwizard/archwizard.css';

:root {
  --asideMenuWidth: 270px;
  --headerHeight: 80px;
}

body {
  background-color: #f3f3f3;
  font-size: 0.875em;
  overflow-x: hidden;
  color: #353c4e !important;
  font-family: 'Open Sans', sans-serif !important;
}

ul,
ol {
  list-style: none;
}

a:hover {
  text-decoration: none;
}

.red-icon {
  color: #fc6180;
}

.bg-c-red {
  background-color: #fc6180;
}

.bg-c-light-violet {
  background-color: purple;
}

.green-icon {
  color: #93be52;
}

.bg-c-green {
  background-color: #93be52;
}

.yellow-icon {
  color: #ffb64d;
}

.bg-c-yellow {
  background-color: #ffb64d;
}

.brown-icon {
  color: #ab7967;
}

.bg-c-brown {
  background-color: #ab7967;
}

.lime-icon {
  color: #39adb5;
}

.bg-c-lime {
  background-color: #39adb5;
}

.purple-icon {
  color: #7c4dff;
}

.bg-c-purple {
  background-color: #7c4dff;
}

.blue-icon {
  color: #4680ff;
}

.dark-icon {
  color: #353c4e;
}

.bg-c-blue {
  background-color: #4680ff;
}

.orange-icon {
  color: var(--orange);
}

.bg-c-orange {
  background-color: var(--orange);
}

.pink-icon {
  color: var(--pink);
}

.bg-c-pink {
  background-color: var(--pink);
}

.bg-c-dark {
  background-color: var(--dark);
}

.cyan-icon {
  color: var(--cyan);
}

.bg-c-cyan {
  background-color: var(--cyan);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

table.dataTable {
  border-collapse: collapse !important;
  width: 100% !important;
}

.table > thead > tr > th {
  border-bottom-color: #ccc;
}

.card-header {
  background-color: inherit !important;
}

.p-dialog-header-icon:enabled:hover {
  background: #fc6180 !important;
}